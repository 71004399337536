import React, { useEffect } from 'react';

import SocialNetworkFreePage from '@components/social-network-free-page/social-network-free-page';
import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import couponCodeService from '@src/utils/couponCodeService';

const Lp30 = () => {
  const { setOffer } = couponCodeService();
  const items = [
    {
      title: 'Take your health quiz',
      description: 'Tell us about your diet & lifestyle in just 5 minutes.',
    },
    {
      title: 'Get your personalised pack',
      description: 'We’ll create your pack based on your answers.',
    },
    {
      title: 'Get 30% off your first 3 month',
      description: 'Get your personalised vitamins. Change or cancel your plan anytime.',
    },
  ];

  const { landing, home } = useFacebookLandingText();
  const text = {
    ...landing,
    ...home,
    hero_text: 'Choose vitamins personalised to you and get ',
    hero_focus: '30% off for 3 months today',
    hero_focus_price: '£27.96',
    how_it_work_header: 'How it works',
    how_it_works_items_list: items,
  };

  useEffect(() => {
    setOffer('4pp', '30off');
  }, []);

  return <SocialNetworkFreePage text={text} noindex={true} />;
};

export default Lp30;
